@import '../../theme.less';

.ant-form-vertical .ant-form-item-label {
  padding: 0 !important;
}

.ant-form-inline .ant-form-item:last-child {
  margin-right: 0;
}

.ant-page-header {
  border-bottom: 1px solid @grey-4;
}

.ant-page-header-heading {
  align-items: center;
}

.ant-page-header-heading-title {
  font-weight: normal !important;
}

.ant-page-header-heading-tags {
  display: flex !important;
}

.ant-popover {
  .ant-popover-inner {
    .ant-list {
      .ant-list-item:first-child {
        padding-top: 0 !important;
      }

      .ant-list-item:last-child {
        padding-bottom: 0 !important;
      }
    }

    .ant-popover-title {
      font-weight: bold !important;
      padding: 7px 12px 4px !important;
    }
  }
}

.ant-select .ant-select-clear {
  opacity: 1 !important; // always show clear button for selects not only on hover
}

.ant-select-dropdown-menu-item-group-title {
  font-size: @font-size-base !important;
}

.ant-spin-container {
  overflow: visible !important;
}

.ant-table-cell div.ant-typography:last-child {
  margin-bottom: 0;
}

.ant-tabs-card {
  & > .ant-tabs-nav {
    margin-bottom: 0 !important;

    &::before {
      display: none !important;
    }

    & .ant-tabs-tab {
      background-color: transparent !important;
      border: none !important;
    }

    & .ant-tabs-tab-active {
      background-color: @component-background !important;
    }
  }

  .ant-tabs-content-holder {
    margin-top: -@card-radius;
  }
}

// expand icon size depends on font-size, we have custom font sizes
.ant-table-row-expand-icon {
  height: ceil(12px * 1.4) !important;
  line-height: 12px !important;
  vertical-align: floor((14px - ceil(12px * 1.4)) / 2) !important;
  width: ceil(12px * 1.4) !important;
}

.ant-table-column-sort {
  background: #f2f2f2 !important;
}

.ant-table-tbody .ant-table-column-sort:hover {
  background: @primary-3 !important;
}

.antd-pro-trend-trendItem {
  font-size: 12px !important;
}

@grey-3: #f5f5f5;@grey-4: #e8e8e8;@grey-5: #d9d9d9;@grey-6: #bfbfbf;@grey-7: #8c8c8c;@grey-8: #595959;@font-family: Lato, sans-serif;@font-size-base: 12px;@font-size-sm: @font-size-base - 2px;@layout-header-height: 48px;@layout-sider-background: #001529;@form-item-margin-bottom: 18px;@menu-collapsed-width: 64px;@page-header-content-padding-vertical: 8px;@page-header-heading-sub-title: 18px;@page-header-heading-title: 20px;@page-header-padding: 16px;@table-header-bg-sm: #fff;@table-padding-vertical: 8px;@table-padding-horizontal: 8px;@border-color-split: hsv(0, 0, 91%);@typography-title-font-weight: 400;