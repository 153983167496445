@import '~antd/lib/style/themes/default.less';

// CSS Variables
:root {
  --menu-dark-item-active-bg: @menu-dark-item-active-bg;
}

// Copied from https://github.com/ant-design/ant-design/blob/8b02a21d365e31e7e7fe32fbd45ed8635a3faac7/site/theme/static/colors.less#L153
// ---
@grey-3: #f5f5f5;
@grey-4: #e8e8e8;
@grey-5: #d9d9d9;
@grey-6: #bfbfbf;
@grey-7: #8c8c8c;
@grey-8: #595959;

// Base Scaffolding Variables
// ---

@font-family: Lato, sans-serif;
@font-size-base: 12px;
@font-size-sm: @font-size-base - 2px;

// Layout
@layout-header-height: 48px;
@layout-sider-background: #001529;

// Form
// ---
@form-item-margin-bottom: 18px;

// Menu
// ---
@menu-collapsed-width: 64px;

// PageHeader
// ---
@page-header-content-padding-vertical: 8px;
@page-header-heading-sub-title: 18px;
@page-header-heading-title: 20px;
@page-header-padding: 16px;

// Table
// --
@table-header-bg-sm: #fff;
@table-padding-vertical: 8px;
@table-padding-horizontal: 8px;
@border-color-split: hsv(0, 0, 91%); // split border inside a component

// Typography
// ---
@typography-title-font-weight: 400;
